import $ from 'jquery';

$(() => {
  $('[data-toggle="slide-panel"]').on('click', (e) => {
    var target = e.currentTarget.attributes.href.value;
    $(target).toggleClass("sliding-panel-open");
  });

  $('[data-close="slide-panel"]').on('click', (e) => {
    var target = e.currentTarget.closest('.sliding-panel-content');
    $(target).toggleClass("sliding-panel-open");
  });
});
