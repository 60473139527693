import $ from 'jquery';

$(document).on('click', 'a[href^="#"]', function (event) {
  event.preventDefault();
  if ($(this).parent().is('[data-scroll]')) {
    $('html, body').animate({
      scrollTop: $($.attr(this, 'href')).offset().top
    }, 500);

    // highlight id data-scroll is highlight
  }
});
