import $ from 'jquery';

$(document).ready(function () {
  var url = document.location.toString()
  if (url.match('#')) {
    $('.nav a[href="#' + url.split('#')[1] + '"]').tab('show')
  }

  // Change hash for page-reload
  $('.nav a').on('shown.bs.tab', function (e) {
    window.location.hash = e.target.hash
  })
})