import 'select2';
import $ from 'jquery';

$(document).ready(function() {
  $('.tags').select2({
    width: '100%',
    theme: 'bootstrap4'
  });
});

import 'select2/dist/css/select2.css';
import 'select2-bootstrap4-theme/dist/select2-bootstrap4.css';
