import Rails from '@rails/ujs';
Rails.start();

import $ from 'jquery';
window.$ = $;

import 'popper.js';
import 'bootstrap';
import 'slick-carousel';
import truncate from 'html-truncate';

import '../components/search-and-filter';
import '../components/smooth-scroll';
import '../components/cookies';
import '../components/flyout';
import '../components/search-toggle';
import '../components/sliding-panel';
import '../components/select-2-init';
import '../components/events-slats';
import '../components/tabs-anchor';


$(function(){
  $('.slick').slick({
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [{
      breakpoint: 992,
      settings: "unslick"
    }]
  });

  $('.mega-menu-close').click(function(){
    $('#megaMenu').collapse('hide');
  });

  $('.read-more-link').click(function(){
    var truncatable = $(this).closest('.truncatable');
    truncatable.find('.read-more').addClass('d-none');
    truncatable.find('.read-less').removeClass('d-none');
  });
});

import 'slick-carousel/slick/slick.css';

import '../stylesheets/application.scss';
