import $ from 'jquery';

$(function(){

  $('#event-slats .event-slat-wrapper').each(function(i, slat){
    $.get($(slat).data('url'), function(data) {
      $(slat).html(data);
    });
  });
});
